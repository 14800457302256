import { Component, Inject } from "@angular/core";
import {
    MatDialog,
    MatDialogRef,
    MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { ConfirmationDialogService } from "app/main/shared/confirmation-dialog/confirmation-dialog.service";

@Component({
    selector: "request-id-not-found",
    templateUrl: "./request-id-not-found.html",
    styles: [
        `
            form {
                width: 600px;
            }
        `,
    ],
})
export class RequestIdNotFound {
    constructor(
        @Inject(MAT_DIALOG_DATA)
        public dialog: MatDialog,
        public dialogRef: MatDialogRef<RequestIdNotFound>,
        private confirmationDialogService: ConfirmationDialogService
    ) {}
    ngOnInit(): void {}

    closeRequestDialog(): void {
        const confirmResult = this.confirmationDialogService.confirm("cancel");
        confirmResult.afterClosed().subscribe((result) => {
            if (result == true) {
                this.dialogRef.close();
            }
        });
    }
}
